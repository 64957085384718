import { useEmailLoginDone } from "@hooks/login/useEmailLoginDone";
import { FlexBox } from "@ui/FlexBox";
import { queryParamToString } from "@uxf_base/helpers/NextContextQueryHelper";
import { useRouter } from "next/router";
import { FC } from "react";
import LoginForm from "./LoginForm";

const LoginComponent: FC = () => {
    const [loginFormSubmitHandler, { loading }] = useEmailLoginDone();
    const router = useRouter();
    const redirUrl = queryParamToString(router.query.redirUrl);

    return (
        <FlexBox flexDirection="column" justifyContent="stretch">
            <LoginForm onSubmit={loginFormSubmitHandler} mutationLoading={loading} redirUrl={redirUrl} />
        </FlexBox>
    );
};

export default LoginComponent;
